/* Importing fonts from Google */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.images-wrap div {
  padding: 20px 10px;
}
@keyframes background-color {
  0% {
    background: linear-gradient(to bottom, #000428, #004683);
  }

  25% {
    background: linear-gradient(135deg, #1a4223, #5ee95e);
  }

  50% {
    background: linear-gradient(to bottom, #421a31, #e95ed2);
  }

  100% {
    background: linear-gradient(-135deg, #fae37d, #881da8, #20668f);
  }
}

.twt-card {
  width: 100%;
  height: 300px;
  transform-style: preserve-3d;
  perspective: 500px;
  border: none;
  background-color: inherit;
}

.twt-card .face {
  /* position: absolute; */
  /* color: #fff; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.06);
  transform-style: preserve-3d;
  transition: 0.5s;
  backface-visibility: hidden;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  /* border-right: 1px solid #999;
border-bottom: 1px solid #999; */
}

.twt-card .face.front-face,
.twt-card .face.back-face {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.twt-card .face.front-face .profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.twt-card .face.front-face .name {
  letter-spacing: 2px;
}

.twt-card .face.front-face .designation {
  font-size: 0.8rem;
  color: #6d6868;
  letter-spacing: 0.8px;
}

.twt-card:hover .face.front-face {
  transform: rotateY(180deg);
}

.twt-card .face.back-face {
  position: absolute;
  background: rgba(255, 255, 255, 0.06);
  transform: rotateY(180deg);
  padding: 5px;
  text-align: center;
  user-select: none;
}

/* .twt-card .face.back-face .icofont-quote-left {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 1.2rem;
}

.twt-card .face.back-face .icofont-quote-right {
  position: absolute;
  bottom: 35px;
  right: 25px;
  font-size: 1.2rem;
} */

.twt-card:hover .face.back-face {
  transform: rotateY(360deg);
}

@media (max-width: 991.5px) {
  .col-lg-4 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

.testimonial {
}
